import React from 'react';

export default function Footer() {
  return (
    <footer className="bg-black small text-center text-white-10">
      <div className="container">
      <a href="/sites" className="white-a footer-link">
        made by mørtified 2021
      </a>
      </div>
    </footer>
  );
}
