module.exports = {
  siteTitle: 'mørtified.space', // <title>
  manifestName: 'Mortified space',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/mortified/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'mørtified.space',
  hpref: 'mørtified',
  greeting:'Hello world',
  greetMsg1:"Hi, I'm James",
  greetMsg2:"I'm a programming student.",

  // social
  socialLinks: [
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/just-mortified',
    },
    {
      icon: 'fa-bandcamp',
      name: 'Bandcamp',
      url: 'https://mortifiedmusic.bandcamp.com/',
    },
    {
      icon: 'fa-youtube',
      name: 'Twitter',
      url: 'https://www.youtube.com/channel/UCi6-PmKqeFV-9FX5HZlCSnw',
    },
  ],
};
