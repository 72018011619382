import React from 'react';

import Layout from '../components/Layout';

import config from '../../config';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Header from '../components/Header';
import Contact from '../components/Contact';

import mus from '../assets/images/music_logo_w.png';




const IndexPage = () => (
<Layout>

  <Header />

  <header className="mast-music masthead">
  <div className=" d-flex h-100 align-items-center">
    <div className="container d-flex h-100 align-items-center">
      <div className="row mx-auto justify-content-center no-gutters mb-5 mb-lg-12">
        <div className="col-lg-11">
          <h1 className="mx-auto my-0 des-h1">{config.hpref}.music</h1>
        </div>
        <div className="col-lg-1 justify-content-right">
          <img className="des-pico" src={mus} alt="" />
        </div>
      </div>
    </div>
  </div>
  </header>

  <section id="p1" className="des-one text-center">
    <div className="container">
      <div className="row">
        <div className="col-lg-11 mx-auto ">
          <p className="mb-4"></p>
        </div>
      </div>
      <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
      <iframe title="A new start" style={{border: 0, width: "400px", height:"472px"}} src="https://bandcamp.com/EmbeddedPlayer/album=2122403171/size=large/bgcol=ffffff/linkcol=0687f5/artwork=small/transparent=true/" seamless><a href="https://mortifiedmusic.bandcamp.com/album/a-new-start">a new start by mørtified</a></iframe>
      <iframe title="Recycling bin" style={{border: 0, width: "400px", height: "340px"}} src="https://bandcamp.com/EmbeddedPlayer/album=1024114943/size=large/bgcol=ffffff/linkcol=0687f5/artwork=small/transparent=true/" seamless><a href="https://mortifiedmusic.bandcamp.com/album/recycling-bin">Recycling Bin by mørtified</a></iframe>
      </div>
    </div>
  </section>

 <Contact />

 <SocialLinks />

 <Footer />

</Layout>
);

export default IndexPage;
